import styled from "styled-components"
import { media } from "utils/Media"

const BlackBox = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    /* height: 100vh; */
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: flex-end;

    @media ${media.lg} {
        /* padding-top: 51px; */
        /* overflow: hidden;
        height: 100vh; */
    }
  
    .section-container {
        /* margin-top: 33px; */
        background-color: ${props => props.theme.colors.yellow};
        margin: 3rem auto 0;
        
        @media ${media.lg} {
            /* position: absolute; */
            top: 0;
            left: 50%;
            width: 900px;
            padding-top: 51px;
            margin-top: 0;
            z-index: 3;
            overflow: hidden;
        }
    }
`

export default BlackBox