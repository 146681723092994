import React from "react"
import {graphql, Link} from 'gatsby';
import Layout from "components/Layout/Layout"
import styled from 'styled-components'
import {media} from "utils/Media"
import Footer from "components/Layout/Footer"
import WhiteBox from "components/shared/WhiteBox"


const Background = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  
  @media ${media.lg} {
      background: url("${props => props.bgImageLg.src}") no-repeat top center;
      background-size: cover;
  }
`

const NavBar = styled.div`
    position: fixed;
    z-index: 500;
    width: 100%;
    max-width: 900px;
    background-color: ${props => props.theme.colors.black};
    padding: 1rem;
    display: flex;
    
    & > a:last-child {
        margin-left: auto;
    }
    
    a {
        color: ${props => props.theme.colors.yellow};
        font-family:"Clarendon W01 Light", Arial, sans-serif;
        font-size: 1rem;
        line-height: 120%;
        font-weight: 300;
        text-transform: uppercase;
        text-align: left;
        color: ${props => props.theme.colors.red};
        transition: all 0.2s ease-in;
        
        @media ${media.sm} {
            font-size: 1.125rem;
        }
        
        &:hover {
            color: ${props => props.theme.colors.white};
            text-decoration: none;
        }
    }
`

const BiosPage = styled(WhiteBox)`
    .bios-section {
        min-height: calc(100vh - 220px);
        padding: 1rem;
        margin-top: 3rem;
        
        @media ${media.lg} {
            padding: 2rem;
        }
    }
    
    
    h1 {
        color: ${props => props.theme.colors.yellow};
        font-family:"Clarendon W01 Light", Arial, sans-serif;
        font-size: 1.5rem;
        font-weight: 300;
        text-transform: uppercase;
        text-align: left;
        color: ${props => props.theme.colors.black};
        margin: 0 0 1rem;
        
        @media ${media.sm} {
            font-size: 2rem;
        }
    }
    
    h2 {
        color: ${props => props.theme.colors.yellow};
        font-family:"Clarendon W01 Medium", Arial, sans-serif;
        font-size: 0.875rem;
        line-height: 120%;
        font-weight: 500;
        text-transform: uppercase;
        text-align: left;
        color: ${props => props.theme.colors.brown};
        margin: 1rem 0;
        
        @media ${media.sm} {
            font-size: 1.125rem;
        }
    }
    
    hr {
        border-top: 1px solid ${props => props.theme.colors.black};
        margin-bottom: 1rem;
    }
    
`

const BackBtn = styled(Link)`
    width: 140px;
    height: 36px;
    font-size: 1.125rem;
    background-color: ${props => props.theme.colors.blue};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-family: "Clarendon W01 Bold", Arial, sans-serif;
    font-weight: bold;
`

export default ({data}) => {
    const post = data.newsJson
    return (
        <Layout slug={post.slug} title={post.metaTitle} description={post.metaDescription}
                page={post.slug}>
            <Background
                bgImageLg={data.bg.childImageSharp.fluid}
            />
            <BiosPage>
                <section className="section-container">
                    <NavBar>
                        <Link to="/news/">&lt; News</Link>
                        {
                            post.nextSlug ? (<Link to={`/${post.nextSlug}`}>Next article ></Link>) : ''
                        }
                    </NavBar>
                    <div className="bios-section section">
                        <div className="section-fixed-width company-members">
                            <h1>{post.title}</h1>
                            <h2>{post.subtitle}</h2>
                            <hr/>
                            <div dangerouslySetInnerHTML={{
                                __html: `${post.copy}`
                            }}/>
                            <BackBtn to="/news/">Back</BackBtn>
                        </div>
                    </div>
                    <Footer/>
                </section>
            </BiosPage>
        </Layout>
    )
}

export const query = graphql`
  query($slug: String!) {
    newsJson(slug: { eq: $slug }) {
        title
        subtitle
        shortCopy
        slug
        copy
        nextSlug
        metaTitle
        metaDescription
    }
     bg: file(relativePath: { eq: "bg-bios.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        mobileTitle: file(relativePath: { eq: "mobile-title.png" }) {
            childImageSharp {
                fluid(maxWidth: 694) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
  }
`